<template lang="pug">
div.root_container.data_landing
  vue-headful(title="Ownmesh datas")
  data-modal(ref="data_modal")
  div.row.filter_options
    div.col.host_filter_form
      b-form-select(v-model="host_filter" :options="host_filter_form_options")
    div.col
      b-form-checkbox.checkbox_label(v-model="pending_filter") Only pending
    div.col.vertical_center
      b-form-checkbox.checkbox_label(v-model="alarm_filter") Only alarm
    div.col.search_widget
      div.icon
        icon(name="search")
      b-form-input(v-model="search_input" type="text" placeholder="Search")

  b-table.data_list#pager-aria(small hover :items="filtered_data_map" :fields="data_fields"
                    :filter="search_input" :per-page="per_page" :current-page="current_page")
    template(v-slot:cell(name)="data")
      div.data_name(v-bind:style="{'background-color': data.item.color}")
        | {{ data.item.name }}
    template(v-slot:cell(host_name)="data")
      div(v-bind:style="{'background-color': data.item.color}")
        | {{ data.item.host_name }}
    template(v-slot:cell(last_value)="data")
      div.value(v-bind:style="{'background-color': data.item.color}"
                @click="show_data_modal(data.item)")
        div.right
          data-widget(:data="data.item" :value="last_value(data.item)" size="big")
          div.value_pubdate {{ value_pubdate(data.item) }}
  b-pagination(v-model="current_page" v-on:change="on_page_changed" size="lg" align="center"
               :total-rows="filtered_data_map.length" :per-page="per_page"
               aria-controls="pager-aria")
</template>

<script>
import { mapGetters } from 'vuex'
import Icon from 'vue-awesome/components/Icon'
import bus from '@/services/bus'
import { get_element_by_id, get_index_by_id } from '@/utils'
import Data_widget from '@/components/Data_widget'
import Data_modal from '@/components/Data_modal.vue'

import 'vue-awesome/icons/search'
var moment = require('moment')
var host_filter_form_default = [
  { text: 'Host', value: null },
  { text: 'Online', value: 'isOnline' },
  { text: 'Offline', value: 'isOffline' }
]

export default ({
  name: 'Data_landing',
  components: {
    'data-widget': Data_widget,
    'data-modal': Data_modal,
    Icon
  },
  data () {
    return {
      current_page: 1,
      per_page: 15,
      data_modal: null,
      search_input: null,
      filtered_data_map: [],
      data_fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'host_name', label: 'Host', sortable: true },
        { key: 'last_value', label: 'Last value' }
      ],
      host_filter: null,
      host_filter_form_options: host_filter_form_default,
      pending_filter: false,
      alarm_filter: false
    }
  },
  
  computed: {
    ...mapGetters({
      data_map: 'get_data_map',
      host_landing_state: 'get_host_landing_state',
      data_landing_state: 'get_data_landing_state',
      lastentry_map: 'get_lastentry_map',
      entry_map: 'get_entry_map',
      host_map: 'get_host_map'
    })
  },

  watch: {
    host_filter: function (unused_newvalue, unused_oldvalue) {
      this.filtered_data_map = []
      for (let data of this.data_map) this.add_data(data)
    },
    pending_filter: function (unused_newvalue, unused_oldvalue) {
      this.filtered_data_map = []
      for (let data of this.data_map) this.add_data(data)
    },
    alarm_filter: function (unused_newvalue, unused_oldvalue) {
      this.filtered_data_map = []
      for (let data of this.data_map) this.add_data(data)
    }
  },

  mounted () {
    this.data_modal = this.$refs['data_modal']
    if (this.data_landing_state) {
      for (let data of this.data_map) this.add_data(data)
    }
    bus.$on('socket/state', (unused_response) => {
      if (this.data_landing_state) {
        bus.$emit('route/ready', null)
        return
      }
      let already_fetched = ''
      if (this.host_landing_state) {
        already_fetched += 'host,'
        this.set_host_options()
      }
      if (this.event_landing_state) { already_fetched += 'event' }
      this.$socket.send('data_landing', JSON.stringify({already_fetched: already_fetched}))
    })
    bus.$on('state/data_landing', (unused_response) => {
      // TODO: add here bus listeners for all data / lastentry updates
      this.set_host_options()
      bus.$emit('route/ready', null)
    })
    bus.$on('update/data', (response) => {
      let data = get_element_by_id(this.data_map, response.id)
      if (data == null) return
      if (get_index_by_id(this.filtered_data_map, response.id) !== -1) return
      this.add_data(data)
    })
    this.$socket.connect()
  },

  beforeDestroy: function () {
    bus.$off('socket/state')
    bus.$off('state/data_landing')
    bus.$off('update/data')
  },

  methods: {
    last_value: function (data) {
      let last_entry = get_element_by_id(this.lastentry_map, data.id)
      return last_entry ? last_entry.value : false
    },

    value_pubdate: function (data) {
      let last_entry = get_element_by_id(this.lastentry_map, data.id)
      if (!last_entry) { return 'None' }
      return `${moment(last_entry.pub_date).format('ddd DD/MM, H:mm')}`
    },

    show_data_modal: function (data) {
      this.data_modal.show(data.id)
    },

    get_alarm_start: function (data) {
      let alarm_entry = null
      if (data.alarm_data.length) {
        alarm_entry = get_element_by_id(this.lastentry_map, data.alarm_data[0])
      }
      if (alarm_entry) return `${moment(alarm_entry.pub_date).format('ddd DD/MM, H:mm')}`
    },

    on_page_changed: function (unused_page) {
      console.log('page change !')
      window.scrollTo(0, 0)
    },

    add_data: function (data) {
      if (data.role !== 'NO' || !data.visible || !data.enabled) return
      let host = get_element_by_id(this.host_map, data.host)
      data.color = host ? host.color : '#000'
      data.host_name = host ? host.name : 'Unknown'
      if (this.host_filter && host) {
        let host_lastentry = (host.type === 'local') ? {value: true}
          : get_element_by_id(this.lastentry_map, host.netstatus_data)
        if (host_lastentry) {
          if (this.host_filter === 'isOffline' && host_lastentry.value) return
          if (this.host_filter === 'isOnline' && !host_lastentry.value) return
          if (this.host_filter === 'isOffline' || this.host_filter === 'isOnline') {
            this.filtered_data_map.push(data)
            return
          }
        }
        if (host.name !== this.host_filter) return
      }
      if (this.pending_filter && !data.pending) return
      if (this.alarm_filter) {
        if (!data.alarm_data.length) return
        let alarm_entry = get_element_by_id(this.lastentry_map, data.alarm_data[0])
        if (!alarm_entry) return
        if (!alarm_entry.value) return
      }
      this.filtered_data_map.push(data)
    },

    set_host_options: function () {
      this.host_filter_form_options = host_filter_form_default
      for (let host of this.host_map) {
        this.host_filter_form_options.push({ text: host.name, value: host.name })
      }
    }
  },
})
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';


.data_landing {
  // border-left: 4px solid transparent;
  // border-right: 4px solid transparent;
  .filter_options {
    .col {
      padding-left: 1rem;
      padding-right: 1rem;
      position: relative;
    }
    .checkbox_label {
      bottom: 0;
      color: $text_color3;
      font-size: 1.2rem;
      height: 60%;
      margin: auto;
      padding-left: 2rem;
      position: absolute;
      top: 0;
    }
    .custom-control-input {
      &:checked {
        &~ .custom-control-label {
          &::before {
            background-color: $btn_bgcolor;
          }
        }
      }
    }
    .custom-control-label {
      margin-top: .2rem;
      &::before,
      &::after {
        height: 1.3rem;
        width: 1.3rem;
      }
      &::before {
        background-color: $text_color;
      }
    }
    select {
      background-color: lighten($bg_body, 20);
      border: 0;
      font-size: 1.25rem;
      height: 100% !important;
      padding-left: 1.2rem;
    }
    .search_widget,
    .host_filter_form {
      border-radius: 2px;
      color: lighten($black, 20);
      margin-bottom: 7px;
      margin-top: 3px;
    }
    .search_widget {
      background-color: lighten($login_bgcolor, 30);
      border-radius: 2px;
      color: lighten($black, 20);
      display: flex;
      float: right;
      padding: .5rem;
      svg {
        color: lighten($black, 40);
        height: 100%;
        margin-right: 4px;
        width: 15px;
      }
      input {
        background-color: transparent !important;
        border: 0 !important;
        border-radius: 0 !important;
        font-size: 1.2rem;
        padding: 0;
      }
    }
  }
  table {
    &.data_list {
      color: $white;
      font-size: 120%;

      thead {
        color: $black;
        tr {
          th {
            background-color: lighten($login_bgcolor, 30);
            border: 0;
            padding-bottom: .6rem;
            padding-top: 1rem;
            &:first-child {
              border-top-left-radius: 2px;
            }
            &:last-child {
              border-top-right-radius: 2px;
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid transparent;
          // height: 46px;

          td {
            border-top: 0;
            height: 53px;
            padding: 0;

            .fake {
              display: none;
            }

            .data_name {
              // font-size: 110%;
              overflow: hidden;
            }
            .data_value {
              font-size: 180%;
            }

            .value_pubdate {
              font-size: .9rem;
            }

            .vue-switcher {
              margin-bottom: 0;
              // margin-top: 6px;
            }

            .vue-switcher--bold {
              div {
                top: 0;
              }
            }

            .right {
              float: right;
              position: relative;
            }

            .value {
              cursor: pointer;
              position: relative;
              text-align: right;
            }

            .alarm_icon {
              left: -5px;
              top: -5px;
            }

            .pending_icon {
              right: -5px;
              top: -5px;
            }

            > div {
              height: 53px;
              padding: 1rem .5rem;
            }
          }
        }
      }
    }
  }
}





</style>
